import service from '../utils/request'
import VueCookie from 'vue-cookie'
export default {
  namespaced: true,
  state: {
    userInfo: {},
    carsList: [],
    collectList: [],
    vodCookie: VueCookie.get('bencai_vodcookie'),
  },
  mutations: {
    SET_USERINFO(state, data) {
      state.userInfo = data
    },
    SET_CARS(state, data) {
      state.carsList = data
    },
    SET_COLLECT(state, data) {
      state.collectList = data
    },
    UPDATE_USERINFO_PHONE(state, phone) {
      state.userInfo.phone = phone
      try {
        const userInfo = JSON.parse(VueCookie.get('bencaiUserInfo'))
        userInfo.phone = phone
        VueCookie.set('bencaiUserInfo', JSON.stringify(userInfo))
      } catch (e) { }
    },
    // 收藏
    ATTENTION(state, payload) {
      state.collectList.push(payload)
    },
    // 取消收藏
    UNFOLLOW(state, payload) {

      const { collectType, collectContentId } = payload;
      const data = state.collectList.filter(item => {
        return item.collectType != collectType || item.collectContentId != collectContentId
      })
      state.collectList = data
    },
    SET_VOD_COOKIE(state, payload) {
      state.vodCookie = payload
      VueCookie.set('bencai_vodcookie', payload, 7);
      console.log()
    }
  },
  actions: {
    // 刷新用户信息
    getUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        service.get(`/login/refresh`).then(res => {
          if (res.responseCode == 0) {
            let userInfo = Object.assign({}, res.responseBody)
            if (!userInfo.headImg) userInfo.headImg = 'https://resource.jiashengjiaoyu.com/website/website-def-head.png'
            commit('SET_USERINFO', userInfo)
            commit('SET_COLLECT', userInfo.collectRecords)
            delete userInfo.collectRecords
            VueCookie.set('bencaiUserInfo', JSON.stringify(userInfo))
            resolve(res)
          } else {
            reject(res)
            // resolve(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 收藏
    ATTENTION({ commit }, payload) {
      return new Promise((resolve, reject) => {
        service.post('collect/create', payload).then(res => {
          if (res.responseCode === 0) {
            commit('ATTENTION', payload)
            resolve(res)
          } else {
            reject(res)
          }
        })
      })

    },
    // 取消收藏
    UNFOLLOW({ commit }, payload) {
      return new Promise((resolve, reject) => {
        service.get(`collect/delete/${payload.collectType}/${payload.collectContentId}`).then(res => {
          if (res.responseCode === 0) {
            commit('UNFOLLOW', payload)
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    },
    // 数据埋点
    SET_DATA_POINT({ state }, payload) {
      // eventCode: 播放视频类型 登录: 1001 浏览: 1002 分享: 1003 购买: 1004 收藏: 1005
      // typeCode: 课程:1001 短视频:1002 导师:1003 官网访问:1006
      return new Promise((resolve, reject) => {
        const params = {
          eventTypeCode: payload.eventCode || '',
          eventObjectTypeCode: payload.typeCode || '',
          eventObjectId: payload.id || '',
          eventObjectName: payload.title || '',
          eventObjectUrl: payload.url || '', // URL
          userId: state.userInfo.id || '',
          channelTypeCode: 1004,
          srcChannelTypeCode: ''
        }
        service.post('data/dataPoint/addData', params).then(res => {
          if (res.responseCode === 0) {
            resolve(res)
          } else {
            reject(res)
          }
        }, err => reject(res))
      })
    },
    GET_VOD_COOKIE({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        if(state.vodCookie) {
          resolve(state.vodCookie)
        } else {
          service.get('/vod/getCookieId').then(res => {
            if(res.responseCode === 0) {
              commit('SET_VOD_COOKIE', res.responseBody)
              resolve(res.responseBody)
            } else {
              reject(null)
            }
          }).catch(err => {
            reject(null)
          })
        }
      }) 
    }
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // keepAlive  是否需要缓存当前页面数据
  // showHead  true不展示公共的头部
  // showDown  true不展示公共的底部
  // showSildbar true不展示悬浮侧边栏
  {
    path: '/',
    redirect: '/home'
  },
  // 首页
  {
    name: 'home',
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home'),
    meta: {
      name: '首页：学金融，练交易，佳胜步步为赢！'
    }
  },
  // 注册
  {
    name: 'register',
    path: '/register',
    component: () => import(/* webpackChunkName: "home" */ '../views/register.vue'),
    meta: {
      name: '注册'
    }
  },
  // fm
  {
    name: 'fm',
    path: '/fm',
    component: () => import(/* webpackChunkName: "fm" */ '../views/fm/index.vue'),
    meta: {
      name: '公开课'
    }
  },
  {
    name: 'video-play',
    path: '/video-play',
    component: () => import(/* webpackChunkName: "video" */ '../views/fm/short-video-play'),
    meta: {
      name: '短视频详情',
    }
  },
  // 课程
  {
    name: 'course',
    path: '/course',
    component: () => import(/* webpackChunkName: "course" */ '../views/course/index/index.vue'),
    meta: {
      name: '课程',
    }
  },
  {
    name: 'course-detail',
    path: '/course-detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/course/course-detail/index.vue'),
    meta: {
      name: '课程详情',
    }
  },
  {
    name: 'course-play',
    path: '/course-play',
    component: () => import(/* webpackChunkName: "detail" */ '../views/course/course-play.vue'),
    meta: {
      name: '课程播放',
      showSildbar: true
    }
  },
  {
    name: 'course-evaluation',
    path: '/course-evaluation',
    component: () => import(/* webpackChunkName: "evaluation" */ '../views/course/course-evaluation.vue'),
    meta: {
      name: '课程评价'
    }
  },
  // 资讯
  // {
  //   name: 'information',
  //   path: '/information',
  //   component: () => import(/* webpackChunkName: "information" */ '../views/information/index.vue'),
  //   meta: {
  //     name: '资讯：引用财联社电报中心，A股24小时电报。为投资者提供专业的上市公司动态、股市资讯、股票行情、财经新闻、今日股市行情、创业板、新能源汽车、板块投资资讯。'
  //   }
  // },
  // 行情
  {
    name: 'quotation',
    path: '/quotation',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/quotation/index.vue'),
    meta: {
      name: '行情'
    }
  },
  // 关于我们
  {
    name: 'about',
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    meta: {
      name: '佳胜金融学校 - 关于我们'
    }
  },
  // 我的
  {
    name: 'my',
    path: '/my',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/index.vue'),
    meta: {
      name: '我的',
    }
  },
  {
    name: 'my-order',
    path: '/my-order',
    component: () => import(/* webpackChunkName: "order" */ '../views/my/my-order.vue'),
    meta: {
      name: '我的订单'
    }
  },
  {
    name: 'my-course',
    path: '/my-course',
    component: () => import(/* webpackChunkName: "my-course" */ '../views/my/my-course.vue'),
    meta: {
      name: '我的课程'
    }
  },
  // 
  // {
  //   name: 'download',
  //   path: '/download',
  //   component: () => import(/* webpackChunkName: "download" */ '../views/product.vue'),
  //   meta: {
  //     name: '红历：一款集记事、备忘录与股票研究为一体的桌面月历式软件。它能快速记录工作任务，待办事宜，更能助投资者一眼掌握市场龙头脉络，捕捉爆发性机会。是工作计划、个人生活、日常炒股必不可少的常用工具。',
  //     showHead: true,
  //     showDown: true,
  //     showSildbar: true, // 是否展示侧边提示框 true 不需要
  //   }
  // },
  // 其他
  {
    name: 'settlement',
    path: '/settlement',
    component: () => import(/* webpackChunkName: "settlement" */ '../views/settlement.vue'),
    meta: {
      name: '去结算'
    }
  },
  {
    name: 'car',
    path: '/car',
    component: () => import(/* webpackChunkName: "car" */ '../views/car.vue'),
    meta: {
      name: '购物车',
    }
  },
  {
    name: 'teacher',
    path: '/teacher',
    component: () => import(/* webpackChunkName: "teacher" */ '../views/teacher.vue'),
    meta: {
      name: '导师详情'
    }
  },
  // {
  //   name: 'active-list',
  //   path: '/active-list',
  //   component: () => import(/* webpackChunkName: "teacher" */ '../views/active-list.vue'),
  //   meta: {
  //     name: '犇财教育 - 中奖名单'
  //   }
  // },
]

// 解决路由重复点击的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  // 返回页面顶部
  scrollBehavior(to, from, savedPosition) {
    // keepAlive的页面缓存高度 
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
        return { x: 0, y: to.meta.savedPosition || 0 }
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})


export default router

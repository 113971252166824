<template>
  <div class="bottom-bar">
    <div class="bottom-contain">
      <div class="bottom-top">
        <div class="top-left">
          <img :src="logo.image" alt="">
          <div class="ul">
            <router-link to="/about?type=4" class="li" target="_black">意见反馈</router-link>
            <!-- <span>|</span> -->
            <!-- <a href="http://security.bencai.cn/" class="li" target="_black">用户协议</a> -->
            <span>|</span>
            <router-link target="_black" to="/about?type=7" class="li">联系我们</router-link>
          </div>
        </div>
        <div class="top-right">

          <div class="phone-service">
            <div class="phone-box">
              <p class="phone">
                <i></i>
                <span>029-87382159</span>
              </p>
              <p class="phonet">（周一至周五 9:00-18:00）</p>
            </div>
            <div class="service-box">
              <span class="service">在线客服</span>
              <img class="service-img" :src="banners.image" alt="">
              <span class="servicet">（周一至周五 9:00-18:00）</span>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="bottom-bottom">
        <p>Copyright 2022 {{ Copyright.name }} 版权所有 </p>
        <p><a href="https://beian.miit.gov.cn" style="color: inherit;" target=“_blank”>{{ Copyright.number }}</a></p>

        <!-- <p>Copyright 2022 西安佳胜教育科技有限公司 版权所有 </p>
        <p><a href="https://beian.miit.gov.cn" style="color: inherit;" target=“_blank”>陕ICP备2023004873号-1</a></p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  data() {
    return {
      cyqappQrcode: ``, // 超预期app二维码
      cyqWechatQrcode: ``, // 微信公众号二维码
      Copyright: {
        name: "",
        number: ""
      },
      banners:{},
      logo:{}
    }
  },
  mounted() {
    this.getDomain()
    this.getBanner()
  },
  methods: {
    getBanner() {
      let self = this
      self.$axios.get(`/mini/commonBanner?type=24,26`).then(res => {
        if (res.responseCode == 0) {
          self.banners = res.responseBody["24"][0]
          self.logo = res.responseBody["26"][0]
        } else {
          self.$message.error(res.responseMessage)
        }
      }).catch(err => {
        console.log(err, 'err')
      })
    },
    getDomain() {
      const domainInfo = [
        { domain: "jiashengjiaoyu.com", name: "西安佳胜教育科技有限公司", number: "陕ICP备2023004873号-1" },
        { domain: "jsjrxx.cn", name: "西安市碑林区佳胜金融职业技能培训学校", number: "陕ICP备2023000062号-1" },
        { domain: "xn--tlq9s765ce2am57f1nt.com", name: "西安佳胜教育科技有限公司", number: "陕ICP备2023004873号-2" },
        { domain: "xn--qrq240amjkr1yznhr4p.cn", name: "西安市碑林区佳胜金融职业技能培训学校", number: "陕ICP备2023000062号-2" }
      ];
      let currentDomain = document.domain;
      console.log(currentDomain);
      let matchedDomain = null;
      domainInfo.forEach(info => {
        if (currentDomain.indexOf(info.domain) !== -1) {
          matchedDomain = info;
        }
      });
      if (matchedDomain) {
        this.Copyright.name = matchedDomain.name;
        this.Copyright.number = matchedDomain.number;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-bar {
  width: 100%;
  height: 383px;
  background: #222222;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  min-width: 1200px;
}

.bottom-contain {
  width: 1200px;
  min-width: 1200px;
  height: 100%;
}

.bottom-top {
  height: 222px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .top-left {
    display: flex;
    flex-direction: column;

    img {
      width: 120px;
      height: 120px;
    }

    .ul {
      display: flex;
      margin-top: 36px;

      .li {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 16px;
        cursor: pointer;
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 16px;
        margin: 0px 13px;
      }
    }
  }

  .top-right {
    display: flex;
    align-items: center;

    .qr-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 16px;
      }
    }

    .second {
      margin-left: 59px;
    }

    .phone-service {
      margin-left: 74px;

      .phone-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .phone {
          display: flex;
          align-items: center;

          i {
            width: 14px;
            height: 14px;
            background: url("../assets/images/call.png") no-repeat;
            background-size: cover;
          }

          span {
            margin-left: 8px;
            font-size: 16px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #e4b18f;
            line-height: 20px;
          }
        }

        .phonet {
          font-size: 12px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          color: #c1bec1;
          margin-top: 5px;
        }
      }

      .service-box {
        display: flex;
        flex-direction: column;
        margin-top: 31px;
        position: relative;

        .service {
          width: 148px;
          height: 34px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #e4b18f;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #e4b18f;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .servicet {
          font-size: 12px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          color: #c1bec1;
          margin-top: 5px;
        }

        .service-img {
          width: 200px;
          height: 200px;
          position: absolute;
          display: none;
          top: -215px;
          left: -25px;
          z-index: 99;
          border: #222222 1px solid;
        }

        &:hover {
          .service-img {
            display: block;
          }
        }
      }
    }
  }
}

hr {
  opacity: 0.2;
}

.bottom-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  p {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 30px;
  }
}
</style>

<template>
  <div class="u-slide">
    <div class="head">
      <!-- <div class="item" @click="goService()">
        <span class="icon"></span>
        <span class="title">联系客服</span>
      </div> -->
      <div class="item" @click="goFree()">
        <span class="icon edit"></span>
        <span class="title">意见反馈</span>
      </div>
    </div>
    <div class="top" @click="goTop()">
      <span class="icon"></span>
      <span class="text">TOP</span>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  methods: {
    goService() {
      let url = 'https://resource.jiashengjiaoyu.com/website/customer.jpg'
      this.$alert(`<img src="${url}" alt="">`, '微信扫码联系客服', {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
      })
    },
    goDownApp() {
      let url = `https://resource.jiashengjiaoyu.com/website/downLoadApp.jpg`
      this.$alert(
        `<img style="width:400px;height:400px;" src="${url}" alt="">`,
        '扫码下载APP',
        {
          dangerouslyUseHTMLString: true,
          showConfirmButton: false,
        }
      )
    },
    goFree() {
      // window.open(`${window.location.origin}/about?type=4`)

      let routeUrl = this.$router.resolve({
        path: '/about',
        query: { type: 4 },
      })
      window.open(routeUrl.href, '_blank')
    },
    goTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
.u-slide {
  width: 80px;
  // height: 200px;
  position: fixed;
  top: 40%;
  left: 50%;
  margin-left: 650px;
  z-index: 99;
  display: flex;
  flex-direction: column;
}
.head {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #efefef;
  width: 70px;
  .item {
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon {
      width: 22px;
      height: 22px;
      background: url('@/assets/images/service.png');
      background-size: cover;
      margin-bottom: 10px;
    }
    .app {
      width: 20px;
      height: 28px;
      background: url('@/assets/images/phone.png');
      background-size: cover;
    }
    .edit {
      background: url('@/assets/images/edit.png');
      background-size: cover;
    }
    .title {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
}
.top {
  width: 70px;
  height: 70px;
  background: #ffffff;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  cursor: pointer;
  .icon {
    width: 22px;
    height: 11px;
    background: url('@/assets/images/go-top.png');
    background-size: cover;
    margin-bottom: 10px;
  }
  .text {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}
</style>

<template>
  <transition name="fade" mode="out-in" appear>
    <div class="login-container" v-if="show">
      <div class="login-box">
        <!-- 顶部操作区域 -->
        <div class="login-top">
          <i class="iconfont close-button" @click.stop="onClose">&#xe6a6;</i>
        </div>
        <!-- logo -->
        <div class="login-logo">
          <img src="@/assets/images/logo3.png" alt="">
        </div>
        <!-- 登录标题 -->
        <div class="login-title">
          <div class="login-mode">注册</div>
        </div>
        <!-- 登录表单 -->
        <div class="login-form">
          <el-form ref="regFrom" :model="regFrom" :rules="rules">
            <el-form-item label="" prop="phone">
              <el-input placeholder="请输入手机号" maxlength="11" prefix-icon="el-icon-mobile-phone" v-model="regFrom.phone">
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input type="password" placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="regFrom.password" show-password maxlength="18">
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="msgCode" class="form-item">
              <el-input placeholder="请输入短信验证码" prefix-icon="el-icon-lock" v-model="regFrom.msgCode" style="width: 280px;"></el-input>
              <el-button class="send-button" @click="onSendCode">{{sendCodeText}}</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-input placeholder="请输入昵称" prefix-icon="el-icon-user" v-model="regFrom.nick">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="login-button" :loading="isLoading" @click="toRegister">注册</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { getAgentEmployee } from '@/utils/agentEmployee';
export default {
  data() {
    return {
      show: false,
      isLoading: false,
      regFrom: {
        phone: '',
        password: '',
        msgCode: '',
        nick: '',
        msgCategory: 'COMMON_TEMPLATE',
        channelTpCd: '1004'
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/, message: '密码要求至少包含8位字符,由字母或符号或数字组成!', trigger: 'blur' },
        ],
        msgCode: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
        ],
      },
      sendCodeText: '发送验证码'
    }
  },
  mounted() {
    // 记录滚动条宽度 ，在登录框显示时给body添加padding ,减少页面抖动
    this.$nextTick(() => {
      var el = document.createElement("p"),
        styles = {
          width: "100px",
          height: "100px",
          overflowY: "scroll"
        },
        i;
      for (i in styles) {
        el.style[i] = styles[i];
      }
      document.body.appendChild(el);
      this.scrollBarWidth = el.offsetWidth - el.clientWidth;
      el.remove();
    })
  },
  methods: {
    onClose() {
      this.show = false
    },

    // 发送验证码
    onSendCode() {
      let self = this
      if (self.sendCodeText !== '发送验证码') return;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(self.regFrom.phone)) {
        self.$message.error('请输入正确的手机号')
        return
      }
      self.$axios.post(`sms/send/sm/COMMON_TEMPLATE/${self.regFrom.phone}`).then(res => {
        if (res.responseCode == 0) {
          let count = 60
          const fn = () => {
            --count
            if (count > 0) {
              self.sendCodeText = `${count}s后重发`
            } else {
              clearInterval(timer)
              self.sendCodeText = '发送验证码';
            }
          }
          fn()
          let timer = setInterval(fn, 1000)
        } else {
          self.$message.error(res.responseMessage)
        }
      }).catch(err => { })
    },
    // 注册
    toRegister() {
      this.$refs.regFrom.validate((valid) => {
        if (valid) {
          const agentEmployeeId = getAgentEmployee()
          if(agentEmployeeId) this.regFrom.agentEmployeeId = agentEmployeeId
          this.isLoading = true
          this.$axios.post(`/login/register`, this.regFrom).then(res => {
            this.isLoading = false
            if (res.responseCode == 0) {
              this.$refs.regFrom.resetFields()
              delete res.responseBody.collectRecords
              res.responseBody.headImg = res.responseBody.headImg ? res.responseBody.headImg : 'https://resource.jiashengjiaoyu.com/website/website-def-head.png'
              this.$cookie.set('bencaiUserInfo', JSON.stringify(res.responseBody));
              this.$store.commit('account/SET_USERINFO', res.responseBody)
              this.$store.dispatch('account/SET_DATA_POINT', {
                eventCode: 1006,
                userId: res.responseBody.id,
                channelTypeCode: 1004,
                srcChannelTypeCode: ''
              })
              this.show = false
              this.$message.success('注册成功')
            } else {
              this.$message.error(res.responseMessage)
            }
          }).catch(err => { })
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    'show': {
      handler(newValue) {
        if (newValue) {
          let m = function (e) { e.preventDefault(); };
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = this.scrollBarWidth + 'px'
          document.addEventListener("touchmove", m, { passive: false });
        } else {
          let m = function (e) { e.preventDefault(); };
          document.body.style.overflow = '';
          document.body.style.paddingRight = ''
          document.removeEventListener("touchmove", m, { passive: true });
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  user-select: none;

  .login-box {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 478px;
    height: 543px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(38, 38, 38, 0.25);
    border-radius: 10px 10px 10px 10px;

    .login-top {
      height: 56px;
      width: 100%;
      display: flex;
      justify-content: right;
      align-items: center;

      .close-button {
        padding: 10px;
        margin: 7px;
        color: #efefef;
        font-size: 22px;
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }

    .login-logo {
      display: flex;
      justify-content: center;

      img {
        width: 80px;
        height: 80px;
      }
    }

    .login-title {
      margin: 10px 40px 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #666666;

      .login-mode {
        font-size: 20px;
      }

      .login-switch {
        font-size: 14px;
        color: #666666;
      }
    }

    .login-form {
      margin: 20px 40px 0 40px;

      .form-item {
        position: relative;

        .send-button {
          position: absolute;
          right: 0;
          top: 0;
          width: 104px;
          height: 40px;
          background: #fafafc;
          border-radius: 2px 2px 2px 2px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #e4b18f;
          cursor: pointer;
        }
      }
    }

    .login-qrCode {
      text-align: center;
    }

    .login-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #ffffff;
      height: 49px;
      width: 100%;
      border-radius: 2px 2px 2px 2px;
      background: #e4b18f;
      cursor: pointer;
      user-select: none;
    }

    .login-divider {
      padding: 3px 40px 6px 40px;

      .divider-text {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .login-three {
      display: flex;
      justify-content: center;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
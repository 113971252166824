const KEY = 'AgentEmployee'
export const saveAgentEmployee = (value) => {
  if(!value) return
  localStorage.setItem(KEY, value)
}

export const getAgentEmployee = () => {
  return localStorage.getItem(KEY) || null
}

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

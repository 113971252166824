import Vue from 'vue'
import Vuex from 'vuex'
import account from './account'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    userInfo: state => state.account.userInfo,
    collectList: state => state.account.collectList || [],
    vodCookie: state => state.account.vodCookie
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account
  }
})

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import service from './utils/request'
import './assets/style/reset.css'
import element from './element'
import './element-variables.scss' // 修改element主题色
import { Message } from 'element-ui'
import '@/assets/icont/iconfont.css'
import LoginWindowModal from '@/utils/Login'
import VueCookie from 'vue-cookie'

Vue.use(element)
Vue.use(VueCookie)

Vue.config.productionTip = false

Vue.prototype.$axios = service //设置全局变量axios
Vue.prototype.$Login = LoginWindowModal;



router.beforeEach(async (to, from, next) => {
  // console.log(to)
  let userInfo = JSON.parse(VueCookie.get('bencaiUserInfo')) || {}
  // 需要登录才能访问
  if ((to.path == '/quotation') && (Object.keys(userInfo).length == 0)) {
    Vue.prototype.$alert('请登录后访问', '提示', {
      confirmButtonText: '登录',
      showClose: false,
      closeOnPressEscape: false,
      callback: action => {
        LoginWindowModal({
          openLogin: true,
          routerNext: next
        })
      }
    });
  } else {
    // 缓存信息存在 说明是用户登录
    if (Object.keys(userInfo).length > 0) {
      store.dispatch('account/getUserInfo')
      // 微信换绑 地址跳转
      if (to.query.code == 200) {
        next('/my?type=7')
      }
      next()
    } else if (to.query.code == 200) { // 微信扫码处理
      if (to.query.token) {
        VueCookie.set('bencai-authorization', to.query.token)
      }
      store.dispatch('account/getUserInfo').then(res => {
        Message.success('扫码登录成功')
      }).catch(err => {
        Message.warning(err)
      })
      next('/')
    }
    next()
  }
})



router.afterEach((to, from) => { //这里不接收next
  if (to.meta.name) {
    document.title = to.meta.name ? `${to.meta.name}` : '佳胜金融学校'
  }
})




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




import axios from 'axios'
import { MessageBox } from 'element-ui'
import router from '../router'
import store from '../store'
import LoginWindowModal from '@/utils/Login'
import VueCookie from 'vue-cookie'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken()
    // }
    config.headers['ctc'] = 4001
    if (VueCookie.get('bencai-authorization')) {
      config.headers['authorization'] = VueCookie.get('bencai-authorization')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

let MessageBoxFlag = true
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // 
    if (response.headers['authorization']) {
      VueCookie.set('bencai-authorization', response.headers['authorization'])
    }
    // if the custom code is not 20000, it is judged as an error.
    // 登录失效处理
    if (res.responseCode == 40500 && MessageBoxFlag) {
      MessageBoxFlag = false
      let message = "请登录后操作！"
      if (VueCookie.get('bencai-authorization')) {
        message = "登录信息已失效，请重新登录！"
      }
      MessageBox.alert(`${message}`, '提示', {
        confirmButtonText: '登录',
        callback: action => {
          MessageBoxFlag = true
          if (action == 'confirm') {
            LoginWindowModal({
              openLogin: true
            })
          } else {
            store.commit('account/SET_USERINFO', {})
            VueCookie.delete('bencaiUserInfo')
            VueCookie.delete('bencai-authorization')
            if (message == "登录信息已失效，请重新登录！") {
              router.push('/home')
            }
          }
        }
      });
      return Promise.reject(res)
    } else if (res.responseCode == 40005) {
      MessageBox.alert(`当前账号暂未绑定手机号`, '提示', {
        confirmButtonText: '去绑定',
        callback: action => {
          if (action == 'confirm') {
            LoginWindowModal({
              openLogin: true,
              isAccountLogin: true,
              isWechatLogin: true,
              loginTypeDes: '未绑定手机号'
            })
          }
        }
      });
    } else if (MessageBoxFlag) {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service

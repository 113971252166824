import Vue from 'vue';
import LoginWindow from '@/components/Login.vue'; //引入刚才写的弹框组件 
let LoginWindowConstructor = Vue.extend(LoginWindow); // 返回一个“扩展实例构造器” 

let LoginWindowModal = (o) => {
  let LoginWindowDom = new LoginWindowConstructor({
    el: document.createElement('div') //将组件挂载到新创建的div上 
  })
  document.body.appendChild(LoginWindowDom.$el); //把组件的dom添加到body里 

  // 是否展示
  LoginWindowDom.openLogin = o.openLogin
  LoginWindowDom.isPhoneLogin = !o.isPhoneLogin // true 不展示
  LoginWindowDom.isAccountLogin = !o.isAccountLogin // true 不展示 
  LoginWindowDom.isWechatLogin = !o.isWechatLogin // true 不展示
  o.wechatType != 'undefined' && (LoginWindowDom.wechatType = o.wechatType) // 1微信绑定 2微信换绑
  o.routerNext != 'undefined' && (LoginWindowDom.routerNext = o.routerNext) // 导航守卫的next
  o.loginTypeDes != 'undefined' && (LoginWindowDom.loginTypeDes = o.loginTypeDes) // 特殊逻辑传递的值
  if (o.changeIndex == 3) {
    LoginWindowDom.changeIndex = o.changeIndex
  } else {
    LoginWindowDom.changeIndex = 1
  }
  // LoginWindowDom.dialogVisible = o.show || false;
  // (o.accountShow != 'undefined') && (LoginWindowDom.accountShow = o.accountShow);
  // (o.wechatShow != 'undefined') && (LoginWindowDom.wechatShow = !!o.wechatShow);
  // o.title && (LoginWindowDom.title = o.title);
  // LoginWindowDom.backNext = (o.postNext != 'undefined') ? o.postNext : null
}
export default LoginWindowModal;


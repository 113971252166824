<template>
  <div id="app">
    <TopBar v-if="!$route.meta.showHead" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <BottomBar v-if="!$route.meta.showDown" />
    <SlideSet v-if="!$route.meta.showSildbar" />
  </div>
</template>
<script>
import { TopBar, BottomBar, SlideSet } from './components'
export default {
  components: {
    TopBar,
    BottomBar,
    SlideSet
  }
}
</script>


<style lang="scss">
// html {
//   -webkit-filter: grayscale(100%);
//   -moz-filter: grayscale(100%);
//   -ms-filter: grayscale(100%);
//   -o-filter: grayscale(100%);
//   -webkit-filter: grayscale(1);
//   filter: grayscale(100%);
// }
</style>

<template>
  <transition name="fade" mode="out-in" appear>
    <div class="login-container" v-if="openLogin">
      <div class="login-box">
        <!-- 顶部操作区域 -->
        <div class="login-top">
          <i class="iconfont close-button" @click="closeLogin()">&#xe6a6;</i>
        </div>
        <!-- logo -->
        <div class="login-logo">
          <img src="@/assets/images/logo3.png" alt="佳胜金融学校">
        </div>
        <!-- 登录标题 -->
        <div class="login-title">
          <template v-if="loginTypeDes == '未绑定手机号'">
            <div class="login-mode">绑定手机号</div>
          </template>
          <template v-else>
            <div v-if="changeIndex == 3"></div>
            <div v-else class="login-mode"> {{changeIndex == 1?'验证码登录':'密码登录'}}</div>
            <el-button v-if="(isPhoneLogin && isAccountLogin)" type="text" class="login-switch" @click="loginTypeChange()">{{changeIndex == 1?'密码登录':'验证码登录'}} ></el-button>
          </template>
        </div>
        <!-- 登录表单 -->
        <div class="login-form">
          <!-- 验证码登录 -->
          <template v-if="isPhoneLogin && changeIndex == 1">
            <el-form ref="phoneFrom" :model="phoneFrom" :rules="rules" key="phoneFrom">
              <el-form-item label="" prop="phone">
                <el-input placeholder="请输入手机号" maxlength="11" prefix-icon="el-icon-mobile-phone" v-model="phoneFrom.phone">
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="msgCode" class="form-item" key="msgCode">
                <el-input placeholder="请输入短信验证码" @keyup.enter.native="toLogin" prefix-icon="el-icon-lock" v-model="phoneFrom.msgCode" maxlength="6" style="width: 280px;"></el-input>
                <div class="send-button" @click="onSendCode">{{ sendCodeText }}</div>
              </el-form-item>
            </el-form>
          </template>
          <!-- 账号密码登录 -->
          <template v-else-if="isAccountLogin && changeIndex == 2">
            <el-form ref="accountFrom" :model="accountFrom" :rules="accountRules">
              <el-form-item label="" prop="phone" key="phone">
                <el-input placeholder="请输入手机号" maxlength="11" prefix-icon="el-icon-mobile-phone" v-model="accountFrom.phone">
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password" key="password">
                <el-input type="password" placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="accountFrom.password" show-password @keyup.enter.native="toLogin">
                </el-input>
              </el-form-item>
            </el-form>
          </template>
          <!-- 扫码登录 -->
          <template v-else-if="isWechatLogin && changeIndex == 3">
            <div class="login-qrCode" id="login_container"></div>
          </template>
          <!-- 登录按钮 -->
          <template v-if="loginTypeDes == '未绑定手机号'">
            <el-button class="login-button" :loading="isLoading" @click.stop="goBind()">绑定</el-button>
          </template>
          <template v-else>
            <el-button v-if="changeIndex != 3" class="login-button" :loading="isLoading" @click="toLogin()">登录</el-button>
          </template>
        </div>
        <!-- 三方登录 -->
        <!-- <template v-if="isWechatLogin && changeIndex != 3">
          <div class="login-divider">
            <el-divider>
              <span class="divider-text">第三方登录</span>
            </el-divider>
          </div>
          <div class="login-three">
            <img src="@/assets/images/weixin.png" alt="微信登录" @click="wechatLogin()">
          </div>
        </template> -->
      </div>
    </div>
  </transition>
</template>

<script>
import '../utils/wechatQrcode'
import { getAgentEmployee } from '@/utils/agentEmployee';
import router from '../router'
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机号不能为空'));
        return
      }
      //使用正则表达式进行验证手机号码
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('手机号不正确'));
        return
      }
      callback()
    };
    return {
      phoneFrom: {
        phone: '',
        msgCode: '',
        msgCategory: 'COMMON_TEMPLATE',
        channelTpCd: '1004', // 公众号：1001 后台管理：1002 APP:1003 官网：1004 红历：1005
      },
      rules: {
        phone: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        msgCode: [
          { min: 6, max: 6, message: '请输入正确的验证码', trigger: 'blur' },
          { required: true, message: '请输入正确的验证码', trigger: 'blur' }
        ]
      },
      accountFrom: {
        phone: '',
        password: '',
      },
      accountRules: {
        phone: [
          { validator: validatePhone, trigger: 'blur' },
        ],
        password: [
          { required: true, min: 6, message: '请输入正确格式的密码', trigger: 'blur' }
        ]
      },
      sendCodeText: '发送验证码',
      isLoading: false, // loading
      openLogin: false, // 控制登录弹窗show or hide
      isPhoneLogin: true,   // 是否展示验证码登录模块 默认展示
      isAccountLogin: true,  // 是否展示密码登录模块 默认展示
      isWechatLogin: true,  // 是否展示微信登录模块 默认展示
      changeIndex: 1, // 1验证码登录 2密码登录 3微信登录
      wechatType: 1, //  1扫码登录 2换绑
      loginTypeDes: '', // 判断当前是什么操作，以便做特殊逻辑
    }
  },
  mounted() {
    // 记录滚动条宽度 ，在登录框显示时给body添加padding ,减少页面抖动
    this.$nextTick(() => {
      var el = document.createElement("p"),
        styles = {
          width: "100px",
          height: "100px",
          overflowY: "scroll"
        },
        i;
      for (i in styles) {
        el.style[i] = styles[i];
      }
      document.body.appendChild(el);
      this.scrollBarWidth = el.offsetWidth - el.clientWidth;
      el.remove();
    })
  },
  methods: {
    // 登录方式切换
    loginTypeChange() {
      if (this.changeIndex == 1) {
        this.changeIndex = 2
      } else {
        this.changeIndex = 1
      }
    },
    // 微信登录
    wechatLogin() {
      this.changeIndex = 3
    },
    // 发送验证码
    onSendCode() {
      let self = this
      if (self.sendCodeText !== '发送验证码') return;
      self.$refs.phoneFrom.validateField(['phone'], (valid) => {
        if (!valid) {
          self.$axios.post(`/sms/send/sm/COMMON_TEMPLATE/${self.phoneFrom.phone}`).then(res => {
            if (res.responseCode == 0) {
              let count = 60
              const fn = () => {
                --count
                if (count > 0) {
                  self.sendCodeText = `${count}s后重发`
                } else {
                  clearInterval(timer)
                  self.sendCodeText = '发送验证码';
                }
              }
              fn()
              let timer = setInterval(fn, 1000)
            } else {
              self.$message.error(res.responseMessage)
            }
          }).catch(err => { })

        }
      });
    },
    toLogin() {
      if (this.changeIndex == 1) {
        this.codeLogin()
      } else if (this.changeIndex == 2) {
        this.accountLogin()
      }
    },
    // 验证码登录
    codeLogin() {
      let self = this
      self.$refs.phoneFrom.validate((valid) => {
        if (valid) {
          self.isLoading = true
          const agentEmployeeId = getAgentEmployee()
          if(agentEmployeeId) self.phoneFrom.agentEmployeeId = agentEmployeeId
          self.$axios.post(`/login/sms`, self.phoneFrom).then(res => {
            self.isLoading = false
            if (res.responseCode == 0) {
              const data = res.responseBody
              data.headImg = res.responseBody.headImg ? res.responseBody.headImg : 'https://resource.jiashengjiaoyu.com/website/website-def-head.png'
              delete data.collectRecords
              self.$cookie.set('bencaiUserInfo', JSON.stringify(data));
              self.$message({
                type: 'success',
                message: '登录成功',
                duration: 500
              })
              self.SET_DATA_POINT(res.responseBody)
              window.location.reload()
              if (!res.responseBody.isLogin) {
                let dpas = {
                  eventTypeCode: 1006,
                  channelTypeCode: 1004,
                  userId: res.responseBody.id,
                  srcChannelTypeCode: ''
                }
                self.$axios.post(`/data/dataPoint/addData`, dpas)
              }
            } else {
              self.$message.error(res.responseMessage)
            }
          }, err => { })
        }
      });
    },
    // 账户密码事件
    accountLogin() {
      let self = this
      self.$refs.accountFrom.validate(valid => {
        if (valid) {
          self.isLoading = true
          self.$axios.post(`login/password`, self.accountFrom).then(res => {
            self.isLoading = false
            if (res.responseCode == 0) {
              let data = res.responseBody;
              data.headImg = res.responseBody.headImg ? res.responseBody.headImg : 'https://resource.jiashengjiaoyu.com/website/website-def-head.png'
              delete data.collectRecords
              self.$cookie.set('bencaiUserInfo', JSON.stringify(data));
              window.location.reload()
              self.SET_DATA_POINT(res.responseBody).then(res => {
                // if (self.backNext != null) {
                //   self.backNext()
                // }
              })
              self.$message({
                type: 'success',
                message: '登录成功',
                duration: 500
              })
            } else {
              self.$message.error(res.responseMessage)
            }
          }).catch(err => { })
        }
      })
    },
    // 埋点
    SET_DATA_POINT(userInfo) {
      // eventCode: 播放视频类型 登录: 1001 浏览: 1002 分享: 1003 购买: 1004 收藏: 1005
      // typeCode: 课程:1001 短视频:1002 导师:1003
      return new Promise((resolve, reject) => {
        const params = {
          eventTypeCode: 1001,
          userId: userInfo.id,
          channelTypeCode: 1004,
          srcChannelTypeCode: ''
        }
        this.$axios.post('data/dataPoint/addData', params).then(res => {
          if (res.responseCode === 0) {
            resolve(res)
          } else {
            reject(res)
          }
        }, err => reject(res))
      })
    },
    // 
    closeLogin() {
      if (this.loginTypeDes == "未绑定手机号" || this.loginTypeDes == "换绑/绑定") {
        this.openLogin = false
      } else {
        // 登录信息失效，跳转到首页
        if (this.$cookie.get('bencaiUserInfo')) {
          router.push("/")
        }
        this.$cookie.delete('bencaiUserInfo')
        this.$cookie.delete('bencai-authorization')
        this.openLogin = false
      }
    },
    // 
    goBind() {
      let self = this
      let params = {
        phone: self.phoneFrom.phone,
        msgCode: self.phoneFrom.msgCode,
        msgCategory: 'COMMON_TEMPLATE',
        channelTpCd: '1004', // 
      }
      self.isLoading = true
      self.$axios.post(`/login/bindPhone`, params).then(res => {
        self.isLoading = false
        if (res.responseCode == 0) {
          window.location.reload()
          self.$message({
            type: 'success',
            message: '绑定成功',
            duration: 500
          })
        } else {
          self.$message.error(res.responseMessage)
        }
      }).catch(err => { })
    }
  },
  watch: {
    'openLogin': {
      handler(newValue) {
        if (newValue) {
          let m = function (e) { e.preventDefault(); };
          document.body.style.paddingRight = this.scrollBarWidth + 'px'
          document.body.style.overflow = 'hidden';
          document.addEventListener("touchmove", m, { passive: false });
        } else {
          let m = function (e) { e.preventDefault(); };
          document.body.style.overflow = '';
          document.body.style.paddingRight = ''
          document.removeEventListener("touchmove", m, { passive: true });
          this.loginType = 'code'
        }
      }
    },
    'changeIndex': {
      handler(newValue) {
        if (newValue == 3) {
          this.$nextTick(function () {
            let type = this.wechatType || 1
            let url = encodeURIComponent(`${process.env.VUE_APP_BASE_API}/offiaccount/redirectCallBack/website?type=${type}`)
            if (type == 2) {
              let userInfo = JSON.parse(this.$cookie.get('bencaiUserInfo'))
              url = encodeURIComponent(`${process.env.VUE_APP_BASE_API}/offiaccount/redirectCallBack/website?type=${type}&userId=${userInfo.id}`)
            }
            let obj = new WxLogin({
              self_redirect: false,
              id: "login_container",
              appid: "wx0736a4c52e14a179",
              scope: "snsapi_login",
              redirect_uri: url,
              state: Math.random(),
              style: "",
              href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KI3d4X2RlZmF1bHRfdGlwIHtkaXNwbGF5OiBub25lO30="
            });
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  user-select: none;

  .login-box {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 478px;
    height: 543px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(38, 38, 38, 0.25);
    border-radius: 10px 10px 10px 10px;

    .login-top {
      height: 56px;
      width: 100%;
      display: flex;
      justify-content: right;
      align-items: center;

      .close-button {
        padding: 10px;
        margin: 7px;
        color: #efefef;
        font-size: 22px;
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }

    .login-logo {
      display: flex;
      justify-content: center;

      img {
        width: 80px;
        height: 80px;
      }
    }

    .login-title {
      margin: 10px 40px 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #666666;

      .login-mode {
        font-size: 20px;
      }

      .login-switch {
        font-size: 14px;
        color: #666666;
      }
    }

    .login-form {
      margin: 20px 40px 0 40px;

      .form-item {
        position: relative;

        .send-button {
          position: absolute;
          right: 0;
          top: 0;
          width: 104px;
          height: 40px;
          background: #fafafc;
          border-radius: 2px 2px 2px 2px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #e4b18f;
          cursor: pointer;
        }
      }
    }

    .login-qrCode {
      text-align: center;
    }

    .login-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #ffffff;
      height: 49px;
      width: 100%;
      border-radius: 2px 2px 2px 2px;
      background: #e4b18f;
      cursor: pointer;
      user-select: none;
    }

    .login-divider {
      padding: 3px 40px 6px 40px;

      .divider-text {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .login-three {
      display: flex;
      justify-content: center;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
import {
  Button,
  Input,
  Radio,
  RadioGroup,
  Form,
  Dialog,
  Upload,
  FormItem,
  Select,
  Option,
  Loading,
  Message,
  Rate,
  Dropdown,
  InputNumber,
  Tabs,
  TabPane,
  MessageBox,
  Collapse,
  CollapseItem,
  Carousel,
  CarouselItem,
  Image,
  Empty,
  Table,
  TableColumn,
  Divider,
  Skeleton,
  SkeletonItem
} from 'element-ui';

const element = {
  install: function (Vue) {
    Vue.use(Button);
    Vue.use(Input);
    Vue.use(Radio);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    Vue.use(Upload);
    Vue.use(RadioGroup);
    Vue.use(Dialog);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Rate);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Dropdown);
    Vue.use(InputNumber);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(Image);
    Vue.use(Empty);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Loading);
    Vue.use(Divider);
    Vue.use(Skeleton);
    Vue.use(SkeletonItem);
    Vue.prototype.$message = Message;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$prompt = MessageBox.prompt;
  }
};
export default element;
<template>
  <div class="header-top">
    <div class="top-bar" ref="topBar">
      <div class="header-contain">
        <div class="logo-box">
          <router-link to="/home">
            <!-- <img class="logo" src="https://resource.jiashengjiaoyu.com/website/head-log.png" alt="" /> -->
            <img class="logo" :src="banners.image" alt="" />
          </router-link>
        </div>
        <!-- 顶部tab栏 -->
        <ul class="bars-box">
          <li class="bar" v-for="(bar,index) in barList" :key="index" :class="{'bar-active':activePath == bar.path}" @click="pathChange(bar)">{{bar.name}}</li>
        </ul>
        <!-- 顶部右边栏 -->
        <div class="info-box">
          <div class="my-course" @click.stop="goMyCoruse()">
            <span class="icon"></span>
            <span class="name">我的课程</span>
          </div>
          <!-- <img class="search" src="../assets/images/search-icon.png" alt=""> -->
          <div class="car-box" @click="goCar()">
            <img class="bus" src="../assets/images/bus-icon.png" alt="">
            <i class="tag" v-if="!!userInfo.count">{{userInfo.count}}</i>
          </div>
          <template v-if="Object.keys(userInfo).length > 0">
            <div class="headImg" @click.stop="goMy()">
              <!-- <span class="name" @click.stop="goMyCoruse()">我的课程</span> -->
              <img class="head" :src="userInfo.headImg" alt="">
            </div>
          </template>
          <template v-else>
            <div class="headImg">
              <span class="name" @click.stop="goLogin()">登录&nbsp;&nbsp;/</span>
              <span class="name" @click.stop="goRegister()">&nbsp;&nbsp;注册</span>
            </div>
          </template>
        </div>
      </div>
      <!--  -->
      <Login ref="logins"></Login>
      <Register ref="register"></Register>
    </div>
  </div>

</template>

<script>
import Login from './Login'
import Register from './Register'
import { mapGetters } from 'vuex'
export default {
  name: 'TopBar',
  components: {
    Login,
    Register,
  },
  data() {
    return {
      barList: [
        {
          name: '首页',
          path: '/home',
          isHot: false
        },
        // {
        //   name: '公开课',
        //   path: '/fm',
        //   isHot: false
        // },
        {
          name: '课程',
          path: '/course',
          isHot: false
        },
        // {
        //   name: '资讯',
        //   path: '/information',
        //   isHot: false
        // },
        // {
        //   name: '行情',
        //   path: '/quotation',
        //   isHot: false
        // },
        // {
        //   name: '红历',
        //   path: '/download',
        //   isHot: false
        // },
        {
          name: '关于我们',
          path: '/about',
          isHot: false
        },
      ],
      banners:{}
    }
  },
  mounted() {
    // sticky布局优先, 禁用滚动条时 减少页面重排重绘
    this.$nextTick(() => {
      const result = CSS.supports('position', 'sticky');
      if (result) {
        this.$refs.topBar.style.position = 'static'
      }
    })
    this.getBanner()
  },
  computed: {
    ...mapGetters(['userInfo']),
    activePath() {
      return this.$route.path
    },
  },
  methods: {
    getBanner() {
      let self = this
      self.$axios.get(`/mini/commonBanner?type=25`).then(res => {
        if (res.responseCode == 0) {
          self.banners = res.responseBody["25"][0]
        } else {
          self.$message.error(res.responseMessage)
        }
      }).catch(err => {
        console.log(err, 'err')
      })
    },
    // 顶部tab栏跳转
    pathChange(item) {
      switch (item.path) {
        case '/download':
          // window.open(`${window.location.origin}${item.path}`);

          let routeUrl = this.$router.resolve({
            path: item.path,
            query: { type: 4 },
          })
          window.open(routeUrl.href, '_blank')
          break
        case '/course':
          this.$router.push({ name: 'course', query: { type: 10 } }); break;
        default: this.$router.push(item.path)
      }
    },
    // 到我的界面
    goMy() {
      this.$router.push('/my')
    },
    // 去登录界面
    goLogin(index) {
      this.$Login({
        openLogin: true,
      })
    },
    // 去注册界面
    goRegister() {
      this.$refs.register.show = true
    },
    // 去购物车
    goCar() {
      this.$router.push({
        name: 'car'
      })
    },
    // 跳转课程页面
    goMyCoruse() {
      this.$router.push({
        name: 'my-course'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-top {
  position: sticky;
  top: 0;
  height: 64px;
  z-index:20;
}
.top-bar {
  width: 100%;
  height: 64px;
  background: #272633;
  min-width: 1200px;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10;
  box-sizing: border-box;
}
.header-contain {
  min-width: 1200px;
  width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-box {
  .logo {
    width: 160px;
    height: 50px;
  }
}
.bars-box {
  flex: 1;
  padding-left: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  .bar {
    height: 100%;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .bar-active {
    background: #efc0a0;
    font-weight: bold;
    color: #272633;
  }
}
.info-box {
  display: flex;
  align-items: center;
  .my-course {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 28px;
    border-radius: 50px 50px 50px 50px;
    border: 1px solid #e4b18f;
    cursor: pointer;
    .icon {
      width: 16px;
      height: 12px;
      background: url("@/assets/images/my-coruse-icon.png");
      background-size: cover;
      margin-right: 6px;
    }
    .name {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #efc0a0;
    }
  }
  .search {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .car-box {
    position: relative;
    cursor: pointer;
    .bus {
      width: 18px;
      height: 18px;
      margin-left: 38px;
    }
    .tag {
      position: absolute;
      top: -12px;
      right: -14px;
      background: red;
      color: #ffffff;
      border-radius: 50%;
      min-width: 16px;
      text-align: center;
      padding: 2px;
    }
  }
  .headImg {
    display: flex;
    align-items: center;
    margin-left: 42px;
    cursor: pointer;
    .name {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .head {
      background: #d6d6d8;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 12px;
      object-fit: cover;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
</style>

